import { environment } from 'src/environments/environment';
import { AppIconNames } from '../../app-icon-names';
import { UokLinkModel } from '../state/detail/models/link';
import { DtoSystemNames } from './dto-system-names.class';
import { NumericRange } from './numeric-range.class';
import { AppRoutes } from '../../app-routes';

/** Class for helper method for Dto */
export class UtilsDto {

  constructor() {
  }


  // Links options
  public static readonly DisplayInfoLinkOneLine = 'OneLine';
  public static readonly DisplayInfoLinkOneColumn = 'OneColumn';
  public static readonly DisplayInfoLinkTwoColumn = 'TwoColumn';
  public static readonly DisplayInfoLinkGroupByType = 'GroupByType';

  // Others: Presentation and Properties
  public static readonly DisplayInfoContentInLine = 'InLine';
  public static readonly DisplayInfoContentHeaderAndContent = 'HeaderAndContent';

  public static GetRouteLinkEdit(dtoName: string, id: number): string {
    return `/uok-editor-draft/edit/${id}`;
  }

  public static GetRouteLinkDetail(id: number | null): string {
    if (id === null) {
      return '/not-found';
    }

    if (id > 0) {
      return `/main/uok-detail/${id}`;
    }
    // I forward to the be-a-pro page the number to inform that the user wants to access a pro content
    // whe clicked from the icon be a pro the number is not passed
    return AppRoutes.beAPro + `/${id}`;
  }

  public static GetRouteLinkKnowledgeMy(): string {
    // I forward to the be-a-pro page the number to inform that the user wants to access a pro content
    // whe clicked from the icon be a pro the number is not passed
    return AppRoutes.myDrafts;
  }

  public static GetRouteLinkIcon(dtoName: string): AppIconNames {
    let icn: AppIconNames;

    switch (dtoName) {
      // case DtoSystemNames.UokAction: {
      //     icn = 'kicon-action';
      //     break;
      // }
      // case DtoSystemNames.UokGuide: {
      //     icn = 'kicon-guide';
      //     break;
      // }
      case DtoSystemNames.UserCustomer: {
        icn = 'kicon-user'; // 'fa fa-user-circle';
        break;
      }
      case DtoSystemNames.UokMeridian: {
        icn = 'kicon-meridian';
        break;
      }
      case DtoSystemNames.UokVocabulary: {
        icn = 'kicon-vocabulary';
        break;
      }
      case DtoSystemNames.UokAcuPoint: {
        icn = 'kicon-acupoint';
        break;
      }
      case DtoSystemNames.UokDigitalMode: {
        icn = 'kicon-digital-mode';
        break;
      }
      case DtoSystemNames.UokFormat: {
        icn = 'kicon-format';
        break;
      }
      case DtoSystemNames.UokMuscle: {
        icn = 'kicon-muscle';
        break;
      }
      case DtoSystemNames.UokMuscleTest: {
        icn = 'kicon-test';
        break;
      }
      case DtoSystemNames.UokTechnique: {
        icn = 'kicon-technique';
        break;
      }
      case DtoSystemNames.UokGroup: {
        icn = 'kicon-group';
        break;
      }
      case DtoSystemNames.UokReference: {
        icn = 'kicon-reference';
        break;
      }
      case DtoSystemNames.UokAuthor: {
        icn = 'kicon-author';
        break;
      }
      case DtoSystemNames.UokFrequency: {
        icn = 'kicon-frequency';
        break;
      }
      case DtoSystemNames.UokBone: {
        icn = 'kicon-bone';
        break;
      }
      case DtoSystemNames.UokJoint: {
        icn = 'kicon-joint';
        break;
      }
      case DtoSystemNames.UokNeuroLymphatic: {
        icn = 'kicon-NL';
        break;
      }
      case DtoSystemNames.UokNeuroVascular: {
        icn = 'kicon-NV';
        break;
      }
      case DtoSystemNames.UokAnatomy: {
        icn = 'kicon-anatomy';
        break;
      }
      default: {
        icn = 'kicon-knowlative';
        break;
      }
    }

    return icn;
  }

  private static GetRegexString(word: string): string {
    return word.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
    // const notAscii = `[^a-zA-Z]`;
    // const lookBehind = `(?<=${notAscii})`;
    // const lookAhead = `(?=${notAscii})`;
    // return `(${lookBehind}${word}${lookAhead})`;
  }

  public static GetLinkedText(sourceText: string, links: UokLinkModel[]): string {
    if (!links || links.length === 0) {
      return sourceText;
    }

    const possibleMatches: Match[] = [];
    const matchRanges = new Map<Match, NumericRange>();

    // Detect all possible matches
    links.forEach((link) => {
      if (link.replacedWords) {
        link.replacedWords.forEach((word) => {
          const regexp = new RegExp(this.GetRegexString(word), 'g');
          // console.log(`Testing word '${word}'.`);

          let match;
          // @ts-ignore
          // tslint:disable-next-line:no-conditional-assignment
          while ((match = regexp.exec(sourceText)) !== null) {
            // console.log(`Found ${match[0]} start=${match.index} end=${regexp.lastIndex} length=${match.length}.`);
            const tmpMatch = new Match({
              startIndex: match.index,
              endIndex: regexp.lastIndex,
              value: match[0],
              link,
            });

            const tmpRange = new NumericRange(tmpMatch.startIndex, tmpMatch.endIndex);

            possibleMatches.push(tmpMatch);
            matchRanges.set(tmpMatch, tmpRange);
          }
        });
      }
    });

    // Order all possible matches by longest match
    const sortedPossibleMatches = possibleMatches
      .sort((left, right): number => {
        return right.matchLength - left.matchLength;
      });

    const lockedRanges: NumericRange[] = [];
    const matchesToApply: Match[] = [];

    // Every "approved" match will lock a part of the original string,
    // invalidating other matches which would modify the same part of the string
    sortedPossibleMatches.forEach(possibleMatch => {
      // tslint:disable-next-line:no-non-null-assertion
      const rangeModifiedByCurrentMatch = matchRanges.get(possibleMatch)!;

      const canApply = lockedRanges.find(range => range.Intersects(rangeModifiedByCurrentMatch)) === undefined;
      if (canApply) {
        lockedRanges.push(rangeModifiedByCurrentMatch);
        matchesToApply.push(possibleMatch);
        // tslint:disable-next-line:max-line-length
        // console.log(`[Length: ${possibleMatch.matchLength}] Locking the range [${rangeModifiedByCurrentMatch.minimum}-${rangeModifiedByCurrentMatch.maximum}] for '${possibleMatch.value}'.`);
      } else {
        // tslint:disable-next-line:max-line-length
        // console.log(`[Length: ${possibleMatch.matchLength}] Skipping the range [${rangeModifiedByCurrentMatch.minimum}-${rangeModifiedByCurrentMatch.maximum}] for '${possibleMatch.value}'.`);
      }
    });

    // Order "approved" matches by descending start index
    const sortedMatchesToApply = matchesToApply
      .sort((left, right): number => {
        return right.startIndex - left.startIndex;
      });

    // "approved" matches must be applied starting from farthest index and going backwards,
    // in order to not invalidate the nearest start indexes
    sortedMatchesToApply.forEach((item) => {
      const txtBefore = sourceText.slice(0, item.startIndex);
      const txtAfter = sourceText.slice(item.endIndex);
      let replaceWith;

      if (item.link.isExternalLink) {
        const link = item.link.urlRight;
        replaceWith = `<a href=${link} class="kUokDetailTextLink" target="_blank" rel="noopener noreferrer">${item.value}</a>`;
      } else {
        const link = this.GetRouteLinkDetail(item.link.uokIdRight);
        replaceWith = `<a class="kUokDetailTextLink" href="${environment.hrefPrefixInTextReplace}${link}">${item.value}</a>`;
      }

      // const txtReplaced = sourceText.slice(item.startIndex, item.endIndex);
      // console.log(`Replacing the range [${item.startIndex}-${item.endIndex}] '${txtReplaced}' with '${replaceWith}'.`);

      sourceText = txtBefore + replaceWith + txtAfter;
      // console.log(`Resulting string is: '${sourceText}'.`);
    });

    return sourceText;
  }

  // TODO: update when adding a new culture
  public static GetLabelForSearchButtons(dtoName: string, culture: string): string {
    switch (dtoName) {
      case DtoSystemNames.UokNeuroLymphatic: {
        switch (culture) {
          case 'es':
            return 'Neuro-linfático';
          case  'it':
            return 'Neurolifatico';
          default:
            return 'Neurolymphatic';
        }
      }
      case DtoSystemNames.UokNeuroVascular: {
        switch (culture) {
          case 'es':
            return 'Neurovascular';
          case  'it':
            return 'Neurovascolare';
          default:
            return 'Neurovascular';
        }
      }
      default: {
        return this.GetLabel(dtoName, culture);
      }
    }
  }

  public static GetLabel(dtoName: string, culture: string): string {
    switch (dtoName) {
      case DtoSystemNames.UserCustomer: {
        switch (culture) {
          case 'es':
            return 'Usuario';
          case  'it':
            return 'Utente';
          default:
            return 'User';
        }
      }
      case DtoSystemNames.UokMeridian: {
        switch (culture) {
          case 'es':
            return 'Meridiano';
          case  'it':
            return 'Meridiano';
          default:
            return 'Meridian';
        }
      }
      case DtoSystemNames.UokVocabulary: {
        switch (culture) {
          case 'es':
            return 'Vocabulario';
          case  'it':
            return 'Vocabolario';
          default:
            return 'Vocabulary';
        }
      }
      case DtoSystemNames.UokAcuPoint: {
        switch (culture) {
          case 'es':
            return 'Punto de Acupuntura';
          case  'it':
            return 'Punto di Agopuntura';
          default:
            return 'Acu Point';
        }
      }
      case DtoSystemNames.UokDigitalMode: {
        switch (culture) {
          case 'es':
            return 'Modo Digital';
          case  'it':
            return 'Modalità Digitale';
          default:
            return 'Digital Mode';
        }
      }
      case DtoSystemNames.UokFormat: {
        switch (culture) {
          case 'es':
            return 'Formato';
          case  'it':
            return 'Formato';
          default:
            return 'Format';
        }
      }
      case DtoSystemNames.UokMuscle: {
        switch (culture) {
          case 'es':
            return 'Músculo';
          case  'it':
            return 'Muscolo';
          default:
            return 'Muscle';
        }
      }
      case DtoSystemNames.UokMuscleTest: {
        switch (culture) {
          case 'es':
            return 'Prueba Muscular';
          case  'it':
            return 'Test Muscolare';
          default:
            return 'Muscle Test';
        }
      }
      case DtoSystemNames.UokTechnique: {
        switch (culture) {
          case 'es':
            return 'Técnica';
          case  'it':
            return 'Tecnica';
          default:
            return 'Technique';
        }
      }
      case DtoSystemNames.UokGroup: {
        switch (culture) {
          case 'es':
            return 'Grupo';
          case  'it':
            return 'Gruppo';
          default:
            return 'Group';
        }
      }
      case DtoSystemNames.UokReference: {
        switch (culture) {
          case 'es':
            return 'Referencia';
          case  'it':
            return 'Riferimento';
          default:
            return 'Reference';
        }
      }
      case DtoSystemNames.UokAuthor: {
        switch (culture) {
          case 'es':
            return 'Autor';
          case  'it':
            return 'Autore';
          default:
            return 'Author';
        }
      }
      case DtoSystemNames.UokFrequency: {
        switch (culture) {
          case 'es':
            return 'Frecuencia';
          case  'it':
            return 'Frequenza';
          default:
            return 'Frequency';
        }
      }
      case DtoSystemNames.UokBone: {
        switch (culture) {
          case 'es':
            return 'Hueso';
          case  'it':
            return 'Osso';
          default:
            return 'Bone';
        }
      }
      case DtoSystemNames.UokJoint: {
        switch (culture) {
          case 'es':
            return 'Articulación';
          case  'it':
            return 'Articolazione';
          default:
            return 'Joint';
        }
      }
      case DtoSystemNames.UokNeuroLymphatic: {
        switch (culture) {
          case 'es':
            return 'Reflejo neuro-linfático';
          case  'it':
            return 'Riflesso neurolinfatico';
          default:
            return 'Neurolymphatic reflex';
        }
      }
      case DtoSystemNames.UokNeuroVascular: {
        switch (culture) {
          case 'es':
            return 'Reflejo neurovascular';
          case  'it':
            return 'Riflesso neurovascolare';
          default:
            return 'Neurovascular reflex';
        }
      }
      case DtoSystemNames.UokAnatomy: {
        switch (culture) {
          case 'es':
            return 'Anatomía';
          case  'it':
            return 'Anatomia';
          default:
            return 'Anatomy';
        }
      }
      default: {
        return 'knowlative';
      }
    }
  }
}

interface INamedMatchParams {
  startIndex: number;
  endIndex: number;
  value: string;
  link: UokLinkModel;
}

class Match {
  readonly startIndex: number = -1;
  readonly endIndex: number = -1;
  readonly value: string = '';
  readonly link: UokLinkModel;
  readonly matchLength!: number;

  constructor(params: INamedMatchParams) {
    this.startIndex = params.startIndex;
    this.endIndex = params.endIndex;
    this.value = params.value;
    this.link = params.link;

    // https://www.sitepen.com/blog/advanced-typescript-concepts-classes-and-types
    this.matchLength = this.calculateMatchLength();
  }

  private calculateMatchLength(): number {
    return this.endIndex - this.startIndex;
  }
}
